

.mainVerifyText{
    font-size: 20px;
    color:white;
    font-weight: 600;
    padding-top:14px;
}
.secondaryVerifyText{

    right:15px;
    font-size: 16px;
    //width:200px;
    color:white;
    position:absolute;
    
    padding-top:18px;
}
@media (min-width:600px){
    .secondaryVerifyText{
    right:55px;
    }
}