.contain{
    overflow:hidden !important;
    width:100%;
    height:100%;
}
.rentalCategoryCarousel {
    margin-left:auto;
    margin-right:auto;
    margin-top:30px;
    max-width:1400px;
    margin-bottom:10px;
   }
   .rentalCategoryCarousel--homePage {
    margin-bottom:44px
   }
   .rentalCategoryCarousel-title {
    font-size:22px;
    font-family:BasisGrotesque, Avenir, Helvetica Neue, Helvetica, sans-serif;
    font-weight:bold;
    margin-bottom:24px;
    line-height:1.4;
    padding:0 30px
   }
   @media (min-width: 992px) {
    .rentalCategoryCarousel-title {
     padding:0 50px
    }
   }
   @media (min-width: 1200px) {
    .rentalCategoryCarousel-title {
     padding:0 40px
    }
   }
   @media (min-width: 1400px) {
    .rentalCategoryCarousel-title {
     padding:0 25px
    }
   }
   .rentalCategoryCarousel-carousel {
    display:flex;
    margin:0 auto;
    justify-content:center;
    padding-left:20px;
    position:relative;
    width:100%
   }
   @media (min-width: 768px) {
    .rentalCategoryCarousel-carousel {
     padding-left:0;
     width:684px
    }
   }
   @media (min-width: 992px) {
    .rentalCategoryCarousel-carousel {
     width:912px
    }
   }
   @media (min-width: 1200px) {
    .rentalCategoryCarousel-carousel {
     width:1140px
    }
   }
   @media (min-width:1400px) {
    .rentalCategoryCarousel-carousel {
     width:1368px
    }
   }

   .rentalCategoryCarousel-carouselMask {
    display:flex;
    flex:0 0 auto;
    width:100%;
    padding:8px 7.5px 20px;
    overflow:hidden !important;
    
    -webkit-overflow-scrolling:touch !important;
   }
   .rentalCategoryCarousel-carouselLeftArrow {
    display:block;
    background:url(/resources/img/seo/rental_type/left-arrow-icon.png) no-repeat 0 0 / 100%;
    display:none;
    position:absolute;
    width:50px;
    height:50px;
    top:50%;
    transform:translateY(-50%);
    margin-top:-20px;
    border:none;
    outline:none;
    z-index:1;
    cursor:pointer;
    left:0;
    margin-left:-10px
   }
   @media (-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
    .rentalCategoryCarousel-carouselLeftArrow {
     background-image:url('/resources/img/seo/rental_type/left-arrow-icon@2x.png')
    }
   }
   @media (min-width: 768px) {
    .rentalCategoryCarousel-carouselLeftArrow {
     display:block
    }
   }
   .rentalCategoryCarousel-carouselRightArrow {
    display:block;
    background:url(/resources/img/seo/rental_type/right-arrow-icon.png) no-repeat 0 0 / 100%;
    display:none;
    position:absolute;
    width:50px;
    height:50px;
    top:50%;
    transform:translateY(-50%);
    margin-top:-20px;
    border:none;
    outline:none;
    z-index:1;
    cursor:pointer;
    right:0;
    margin-right:-10px
   }
   @media (-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
    .rentalCategoryCarousel-carouselRightArrow {
     background-image:url('/resources/img/seo/rental_type/right-arrow-icon@2x.png')
    }
   }
   @media (min-width: 768px) {
    .rentalCategoryCarousel-carouselRightArrow {
     display:block
    }
   }
   .rentalCategoryCarousel-carouselSlides {
    display:flex
   }
   .rentalCategoryCarousel-carouselItem {
    width:213px;
    margin:0 7.5px;
    padding:0;
    border-radius:8px;
    box-shadow:0 2px 4px 0 rgba(35,31,32,0.2),0 0 1px 0 rgba(35,31,32,0.1);
    text-align:center;
    flex:1 0 auto;
    color:#231f20;
    overflow:hidden;
    position:relative;
    user-select:none
   }
   @media (min-width: 992px) {
    .rentalCategoryCarousel-carouselItem {
     transform:translateY(0);
     transition:color .15s ease-in-out,box-shadow .15s ease-in-out,transform .15s ease-in-out
    }
   }
   .rentalCategoryCarousel-carouselItem:after {
    content:"";
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background:rgba(255,255,255,0)
   }
   @media (min-width: 992px) {
    .rentalCategoryCarousel-carouselItem:after {
     transition:.15s ease-in-out
    }
   }
   .rentalCategoryCarousel-carouselItem:first-child {
    margin-left:0
   }
   .rentalCategoryCarousel-carouselItem:last-child {
    margin-right:0
   }
   .rentalCategoryCarousel-carouselItem:hover {
    text-decoration:none;
    color:#f50057;
    box-shadow:0 8px 16px 0 rgba(35,31,32,0.1),0 0 3px 0 rgba(35,31,32,0.05)
   }
   @media (min-width: 992px) {
    .rentalCategoryCarousel-carouselItem:hover {
     transform:translateY(-2px);
     transition:color .15s ease-in-out,box-shadow .15s ease-in-out,transform .15s ease-in-out
    }
   }
   @media (min-width: 992px) {
    .rentalCategoryCarousel-carouselItem:hover:after {
     transition:.15s ease-in-out
    }
   }
   .rentalCategoryCarousel-carouselItemImageContainer {
    height:160px;
    width:213px
   }
   .rentalCategoryCarousel-carouselItemImage {
    width:100%
   }
   .rentalCategoryCarousel-carouselItemText {
    padding:15px;
    font-size:16px;
    line-height:1.5;
    font-weight:bold;
    text-align:center
   }
   .rentalCategoryCarousel--list .rentalCategoryCarousel-carousel {
    width:100%;
    padding:0 22.5px
   }
   @media (min-width: 992px) {
    .rentalCategoryCarousel--list .rentalCategoryCarousel-carousel {
     padding:0 42.5px
    }
   }
   @media (min-width: 1200px) {
    .rentalCategoryCarousel--list .rentalCategoryCarousel-carousel {
     padding:0 32.5px
    }
   }
   @media (min-width: 1500px) {
    .rentalCategoryCarousel--list .rentalCategoryCarousel-carousel {
     padding:0 17.5px
    }
   }
   .rentalCategoryCarousel--list .rentalCategoryCarousel-title {
    margin-bottom:16px
   }
   .rentalCategoryCarousel--list .rentalCategoryCarousel-carouselSlides {
    display:inline-block;
    position:relative
   }
   .rentalCategoryCarousel--list .rentalCategoryCarousel-carouselItemImageContainer {
    display:none
   }
   .rentalCategoryCarousel--list .rentalCategoryCarousel-carouselItem {
    display:inline-block;
    width:auto;
    margin:0 15px 15px 0
   }
   .rentalCategoryCarousel--list .rentalCategoryCarousel-carouselItem:last-child {
    margin-right:0
   }
   .rentalCategoryCarousel--list .rentalCategoryCarousel-carouselLeftArrow {
    display:none
   }
   .rentalCategoryCarousel--list .rentalCategoryCarousel-carouselRightArrow {
    display:none
   }
   .seoCarousel-carouselSlides--noTransition {
    transition:none !important
   }

